import Button from "@/components/Button";
import Wrapper from "@/containers/Wrapper";
import NotFoundPage from "./error/404";

export interface ErrorProps {
  statusCode: number;
}

interface ErrorInitialProps {
  res?: {
    statusCode: number;
  };
  err?: {
    statusCode: number;
  };
}

function Error({ statusCode }: ErrorProps) {
  return (
    <Wrapper>
      <NotFoundPage statusCode={statusCode} />
    </Wrapper>
  );
}

Error.getInitialProps = (props: ErrorInitialProps) => {
  const { res, err } = props;
  const statusCode = res?.statusCode || err?.statusCode || 404;
  return { statusCode };
};

export default Error;
